import React, { Fragment, useContext } from 'react'
import { Link } from 'gatsby'
import './navigation.styles.css'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'

import StoreContext from '~/context/StoreContext'
import { MdShoppingCart } from 'react-icons/md'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = ({ back }) => {
  const [hasItems, quantity] = useQuantity()

  return (
    <Link to={back ? '/' : '/cart'} className="cart_icon">
      {back ? (
        <span className="cart_close">&times;</span>
      ) : (
        <Fragment>
          {hasItems && <span className="cart_counter">{quantity}</span>}
          <MdShoppingCart />
        </Fragment>
      )}
    </Link>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
  back: PropTypes.bool,
}

Navigation.defaultProps = {
  siteTitle: ``,
  back: false,
}

export default Navigation
